<template>
  <v-row justify="center" id="courseTestGroupsCard">
    <!-- Heading -->
    <v-col cols="12" sm="12" class="d-flex justify-space-between">
      <h1 class="text-subtitle-2 text-left d-flex align-center ml-1 ml-md-3">
        <v-icon class="mr-2" color="green" small
          >mdi-book-variant-multiple</v-icon
        >
        All Test Groups:
      </h1>
    </v-col>

    <!-- if Loading -->
    <v-row v-if="loading || superLoading" justify="center" class="mx-md-5">
      <v-col sm="4">
        <v-skeleton-loader
          class="mx-auto"
          max-width="300"
          type="card"
        ></v-skeleton-loader>
      </v-col>
      <v-col sm="4">
        <v-skeleton-loader
          class="mx-auto"
          max-width="300"
          type="card"
        ></v-skeleton-loader>
      </v-col>
      <v-col sm="4" class="hidden-sm-and-down">
        <v-skeleton-loader
          class="mx-auto"
          max-width="300"
          type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <!-- if Error -->
    <v-row v-else-if="error" justify="center" class="my-3">
      <v-col cols="12" md="9">
        <v-alert type="warning" dense text>{{ error }}</v-alert>
      </v-col>
    </v-row>

    <!-- Else Data -->
    <v-col v-else cols="12" sm="12" class="px-0 px-md-4">
      <!-- Empty State -->
      <v-alert
        v-if="!(Object.keys(selectedCourseObj).length > 0)"
        type="info"
        dense
        text
      >
        No such Course found :(
      </v-alert>

      <!-- Empty State 2 -->
      <v-alert
        v-else-if="!(Object.keys(testGroupCounter).length > 0)"
        type="info"
        dense
        text
      >
        No Test Groups added :(
      </v-alert>

      <!-- Slide with Tests -->
      <div v-else>
        <v-container>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="12" md="9" class="mb-2">
              <v-list-item
                v-for="(testGroup, i) in selectedCourseObj.testGroups"
                :key="i"
              >
                <v-list-item-avatar class="mr-2 mr-md-4">
                  <v-icon class="grey lighten-3" color="deep-purple">
                    mdi-book-multiple
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="d-flex align-center">
                    <span class="mr-2">
                      {{ testGroup }}
                    </span>

                    <!-- Tests Available chips -->
                    <v-chip
                      v-if="
                        selectedCourseObj.courseName in testGroupCounter &&
                        testGroup in
                          testGroupCounter[selectedCourseObj.courseName]
                      "
                    >
                      {{
                        testGroupCounter[selectedCourseObj.courseName][
                          testGroup
                        ]
                      }}
                      Tests
                    </v-chip>
                  </v-list-item-title>

                  <v-list-item-subtitle> Test Group </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    :disabled="
                      !(
                        selectedCourseObj.courseName in testGroupCounter &&
                        testGroup in
                          testGroupCounter[selectedCourseObj.courseName] &&
                        testGroupCounter[selectedCourseObj.courseName][
                          testGroup
                        ] > 0
                      )
                    "
                    :to="`/course-tests/${courseIndex}/testGroup/${i}`"
                    outlined
                    small
                  >
                    View Tests
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "CourseTestGroupsCard",
  props: ["superLoading", "courseIndex", "selectedCourseObj"],

  data: () => ({
    loading: true,
    error: "",
    testGroupCounter: {},
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },

    fetchTestGroupCounter() {
      this.setLoading(true);
      // get courses array from store
      const testGroupCounter = this.$store.getters.testGroupCounter;

      //if not found in store
      if (
        Object.keys(testGroupCounter).length <= 0 ||
        typeof testGroupCounter == "undefined"
      ) {
        //get folder names from server
        this.$store
          .dispatch("getTestGroupCounter")
          .then((res) => {
            if (res) {
              this.testGroupCounter = res;
            }
          })
          .catch((error) => {
            console.log(error);
            this.error = error;
          })
          .finally(() => {
            this.setLoading(false);
          });
      } else {
        // if found in store
        this.testGroupCounter = testGroupCounter;
        this.setLoading(false);
      }
    },
  },

  mounted() {
    this.fetchTestGroupCounter();
  },
};
</script>

<style scoped>
.theme--light.v-tabs-items {
  background-color: rgba(255, 255, 255, 0.4) !important;
  border-radius: 1.5%;
}

.card-gradient {
  background: #fc5c7d; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #4e54c8,
    #8f94fb
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top left,
    #4e54c8,
    #8f94fb
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>